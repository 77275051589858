<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th
                      style="width: 50px"
                      class="text-center"
                    >
                      #
                    </th>
                    <th style="width: 80px" />
                    <th>{{ $t('Shareholder Name') }}</th>
                    <th>{{ $t('Code') }}</th>
                    <th>{{ $t('Full Name') }}</th>
                    <th>{{ $t('Share Percentage %') }}</th>
                    <th>{{ $t('Active') }}</th>
                    <th
                      style="width: 200px"
                      class="text-center"
                    >
                      <v-btn
                        depressed
                        color="primary"
                        small
                        @click="addShareholder()"
                      >
                        <v-icon
                          class="mdil-24px"
                          left
                        >
                          mdil-plus
                        </v-icon>
                        {{ $t('Add New') }}
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(holder, index) in companyShareholders"
                    :key="index"
                  >
                    <td class="text-center">
                      {{ index + 1 }}
                    </td>
                    <td class="py-1">
                      <v-avatar size="45">
                        <img
                          :src="holder.profile_picture ? `${$_file_path()}${holder.profile_picture}?token=${$_auth_token()}`: $_profile_placeholder()"
                          :alt="holder.username"
                        >
                      </v-avatar>
                    </td>
                    <td>{{ holder.username }}</td>
                    <td>{{ holder.code }}</td>
                    <td>{{ holder.first_name }}</td>
                    <td>{{ holder.share_percentage }}%</td>
                    <td>
                      <v-switch
                        v-model="holder.is_active"
                        color="success"
                        dense
                        inset
                        :ripple="false"
                        hide-details
                        class="mt-0"
                        @change="activeInactiveHolder(holder)"
                      />
                    </td>
                    <td class="text-center">
                      <v-btn
                        depressed
                        color="info"
                        text
                        @click="editShareholder(holder)"
                      >
                        <v-icon left>
                          mdil-pencil
                        </v-icon>
                        {{ $t('Edit') }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <add-edit-shareholder
      :add-edit-dialog="shareholderDialog"
      :form-data="shareholderForm"
      :edit-mode="editMode"
      @close="shareholderDialog = false"
      @reset-table-data="getCompanyShareholders(company.id), getShareholderTypesList()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import AddEditShareholder from './Shareholder/AddEdit.vue'

export default {
  components: {
    AddEditShareholder
  },

  data() {
    return {
      company: {},
      editMode: false,
      shareholderForm: { babies: [] },
      shareholderDialog: false,
    }
  },

  computed: {
    ...mapGetters([
      'companyShareholders',
    ])
  },

  mounted() {
    this.getViewData().then(() => {
      this.getCompanyShareholders(this.company.id)
      this.getShareholderTypesList()
    })
  },

  methods: {
    ...mapActions([
      'getCompanyShareholders',
      'getShareholderTypesList',
    ]),

    async getViewData() {
      await axios.get(`company/${this.$route.params.id}/`).then(res => {
        this.company = res.data
      })
    },

    activeInactiveHolder(holder) {
      axios.get(`user/${holder.id}/active-inactive/`).then(() => {
        this.$_notify(this.$t('Done'))
      })
    },

    resetForm() {
      this.$refs.theForm.reset()
    },

    addShareholder() {
      this.editMode = false
      this.shareholderForm = {
        company: this.company.id,
        marital_status: 1,
        babies: [],
      }
      this.shareholderDialog = true
    },

    editShareholder(holder) {
      this.editMode = true
      this.shareholderForm = {
        ...holder,
        profile_image_updated: false,
        type: { text: holder.type.name, value: holder.type.id }
      }
      this.shareholderDialog = true
    },

    removeRow(index) {
      this.companyShareholders.splice(index, 1)
    }
  },
}
</script>
